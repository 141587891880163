import Vue from 'vue';
import Router from 'vue-router';
import PageHome from '../views/PageHome.vue';

Vue.use(Router);

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '*',
			name: 'error',
			meta: {
				index: '404',
			},
			component: () => import(/* webpackChunkName: "page-404" */ '../views/PageNotFound.vue'),
		},
		{
			path: '/',
			name: 'home',
			meta: {
				index: '01',
			},
			component: PageHome,
		},
		{
			path: '/about',
			name: 'about',
			meta: {
				index: '02',
			},
			component: () => import(/* webpackChunkName: "page-about" */ '../views/PageAbout.vue'),
		},
		{
			path: '/skills',
			name: 'skills',
			meta: {
				index: '03',
			},
			component: () =>
				import(/* webpackChunkName: "page-skills" */ '../views/PageSkills.vue'),
		},
		{
			path: '/contact',
			name: 'contact',
			meta: {
				index: '04',
			},
			component: () =>
				import(/* webpackChunkName: "page-contact" */ '../views/PageContact.vue'),
		},
		{
			path: '/legals',
			name: 'legals',
			meta: {
				index: '05',
			},
			component: () =>
				import(/* webpackChunkName: "page-legals" */ '../views/PageLegals.vue'),
		},
	],
});
