import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		breakpoint: 'zero',
		device: {
			touch: false,
			showMotionRequest: false,
			motionPermission: false,
		},
		mouseCursor: {
			hover: false,
			focus: false,
			target: null,
			type: null,
		},
		disableScrolling: false,
	},

	mutations: {
		setBreakpoint(state, mediaQueryName) {
			state.breakpoint = mediaQueryName;
		},

		isTouchDevice(state, isTouchDevice) {
			state.device.touch = isTouchDevice;
		},

		showMotionRequest(state, show) {
			state.device.showMotionRequest = show;
		},

		setMotionPermission(state, permission) {
			state.device.motionPermission = permission;
		},

		mouseCursor(state, payload) {
			state.mouseCursor = Object.assign({}, state.mouseCursor, payload);
		},

		disableScrolling(state, disable) {
			state.disableScrolling = disable;
		},
	},

	actions: {
		setBreakpoint({ state, commit }, mediaQueryName) {
			if (state.breakpoint != mediaQueryName) {
				commit('setBreakpoint', mediaQueryName);
			}
		},

		isTouchDevice({ state, commit }, isTouchDevice) {
			if (state.device.touch != isTouchDevice) {
				commit('isTouchDevice', isTouchDevice);
			}
		},

		showMotionRequest({ state, commit }, show) {
			if (state.device.showMotionRequest != show) {
				commit('showMotionRequest', show);
			}
		},

		setMotionPermission({ state, commit }, permission) {
			if (state.device.motionPermission != permission) {
				commit('setMotionPermission', permission);
			}
		},

		mouseCursor({ state, commit }, payload) {
			commit('mouseCursor', payload);
		},

		disableScrolling({ state, commit }, disable = true) {
			if (state.disableScrolling != disable) {
				commit('disableScrolling', disable);
			}
		},
	},
});
