<template>
	<div id="app" class="app-container">
		<mouse-cursor v-if="showCustomCursor" />

		<header class="header">
			<router-link to="/" v-hover="'logo'">
				<site-logo />
			</router-link>
			<main-navigation />
		</header>

		<transition @enter="pageEnter" @leave="pageLeave" :css="false" mode="out-in">
			<router-view :class="'page--' + $route.name"></router-view>
		</transition>

		<route-info />
		<div class="made-in-ffm">made in frankfurt</div>

		<background-grid ref="overlayGrid" :is-overlay="true" v-show="showOverlay" />
		<background-grid />
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import { gsap } from 'gsap';
	import debounce from 'lodash.debounce';
	import MouseCursor from '@/components/MouseCursor.vue';
	import SiteLogo from '@/components/SiteLogo.vue';
	import MainNavigation from '@/components/MainNavigation.vue';
	import RouteInfo from '@/components/RouteInfo.vue';
	import BackgroundGrid from '@/components/BackgroundGrid.vue';

	export default {
		name: 'App',

		components: {
			MouseCursor,
			SiteLogo,
			MainNavigation,
			RouteInfo,
			BackgroundGrid,
		},

		computed: {
			...mapState(['breakpoint', 'device', 'disableScrolling']),

			showCustomCursor() {
				return this.device.touch ? false : true;
			},
		},

		watch: {
			disableScrolling(newValue, oldValue) {
				if (newValue !== oldValue) {
					if (newValue === true && !document.body.classList.contains('no-scroll')) {
						document.body.classList.add('no-scroll');
					} else {
						document.body.classList.remove('no-scroll');
					}
				}
			},
		},

		data() {
			return {
				tl: null,
				showOverlay: false,
			};
		},

		mounted() {
			// init page transition timeline
			this.initOverlayTimeline();

			// check for touch device
			this.touchDeviceCheck();

			// check for device motion event
			this.showMotionRequest();

			// prevent browser saving scroll position
			if ('scrollRestoration' in history) {
				history.scrollRestoration = 'manual';
			}

			// add breakpoint to store and watch resize
			this.setBreakpoint();
			this.resizeHandler = debounce(this.setBreakpoint, 200);
			window.addEventListener('resize', this.resizeHandler);
		},

		destroyed() {
			window.removeEventListener('resize', this.resizeHandler);
		},

		methods: {
			initOverlayTimeline() {
				this.tl = gsap.timeline({
					paused: true,
					onStart: () => {
						this.showOverlay = true;
					},
					onReverseComplete: () => {
						this.showOverlay = false;
					},
					defaults: {
						duration: 0.2,
						ease: 'none',
					},
				});

				this.tl.fromTo(
					this.$refs.overlayGrid.getRandomChildren(),
					{ opacity: 0 },
					{
						opacity: 1,
						stagger: 0.02,
					}
				);
			},

			pageEnter(el, done) {
				window.scrollTo(0, 0);
				this.tl.reverse(0).call(done);
			},

			pageLeave(el, done) {
				this.tl.play(0).call(done);
			},

			getCssVariable(variable) {
				return window.getComputedStyle(document.documentElement).getPropertyValue(variable);
			},

			setBreakpoint() {
				// parse current breakpoint settings
				const breakpoint = {
					name: this.getCssVariable('--breakpoint-name'),
					query: this.getCssVariable('--breakpoint-query'),
				};

				// commit current breakpoint to store
				this.$store.dispatch('setBreakpoint', breakpoint);
			},

			touchDeviceCheck() {
				// check if current device is a touch device
				const isTouchDevice =
					window.matchMedia('(any-hover: none)').matches ||
					window.matchMedia('(any-pointer: coarse)').matches
						? true
						: false;

				// commit device type to store
				this.$store.dispatch('isTouchDevice', isTouchDevice);
			},

			showMotionRequest() {
				if (
					this.device.showMotionRequest == false &&
					window.DeviceOrientationEvent &&
					typeof window.DeviceOrientationEvent.requestPermission === 'function'
				) {
					this.$store.dispatch('showMotionRequest', true);
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.app-container {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: center;
		position: relative;
		z-index: 1;
		width: 100%;
		min-height: calc(100%);
		padding: 0 var(--spacing-page);
		background: var(--gradient-body);
	}

	.header {
		position: fixed;
		z-index: z-index(#{&});
		top: var(--spacing-page);
		left: var(--spacing-page);
		width: calc(100vw - (var(--spacing-page) * 2));
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	.made-in-ffm {
		display: none;

		@include breakpoint($large) {
			position: fixed;
			bottom: var(--spacing-page);
			left: calc(100vw - var(--spacing-page));
			z-index: z-index(#{&});
			display: flex;
			align-items: center;
			font-size: var(--text-xs);
			opacity: 0.6;
			writing-mode: vertical-lr;
			transform: rotate(-180deg) translateX(100%);

			&:after {
				display: block;
				content: '';
				width: 1px;
				height: 100px;
				background: var(--color-white);
				margin: var(--spacing-xs) 0;
			}
		}
	}
</style>
