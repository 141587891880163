<template>
	<div class="nav">
		<transition @enter="openMenu" @leave="closeMenu" @after-leave="afterCloseMenu" :css="false">
			<div class="nav__menu" v-show="showMenu" ref="navContainer">
				<div class="nav__cell nav__cell--1">
					<nav class="nav__cell-inner" ref="mainNav">
						<ul class="nav__linklist" ref="navLinklist">
							<li v-for="link in navLinks" :key="link.href">
								<router-link class="nav__main-link" :to="link.href" v-hover>
									{{ link.title }}
								</router-link>
							</li>
						</ul>

						<div class="nav__meta" ref="navMetaLinks">
							<router-link class="nav__meta-link" to="/legals" v-hover>
								legal notice
							</router-link>
						</div>
					</nav>
				</div>

				<div class="nav__cell nav__cell--2">
					<div class="nav__cell-inner nav__claim" ref="claim">
						<h1 class="title">
							<span class="highlight">creativity</span> <br />
							meets <br />
							technology
						</h1>
					</div>
				</div>

				<div class="nav__cell nav__cell--3">
					<div class="nav__cell-inner nav__socialmedia" ref="socialmedia">
						<h2 class="nav__social-title" ref="socialTitle">
							follow me
						</h2>
						<ul class="nav__social-linklist" ref="socialLinklist">
							<li v-for="link in socialLinks" :key="link.href">
								<a
									class="nav__social-link"
									:href="link.href"
									target="_blank"
									rel="noopener noreferrer"
									v-hover
								>
									<img
										class="nav__social-icon"
										:src="require(`@/assets/svg/brand-icons/${link.icon}.svg`)"
										:alt="link.title"
										width="30"
										height="30"
									/>
								</a>
							</li>
						</ul>
					</div>
				</div>

				<div class="nav__cell nav__cell--4">
					<div class="nav__cell-inner" ref="cell4"></div>
				</div>

				<div class="nav__cell nav__cell--5">
					<div class="nav__cell-inner" ref="cell5"></div>
				</div>
			</div>
		</transition>

		<button
			class="nav__toggle"
			:class="{ 'nav__toggle--close': showMenu }"
			@click="showMenu = !showMenu"
			aria-label="toggle menu"
			v-hover="'menu'"
			ref="navToggle"
		>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" v-bind:class="'nav__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g data-name=".open" fill="#fff"><path d="M18 10h22v4H18z"/><path d="M5 0h35v4H5z"/></g><g data-name=".close" fill="#ff6345"><path d="M0 20h40v4H0z"/><path d="M0 20h40v4H0z"/></g></svg>
		</button>
	</div>
</template>

<script>
	import { gsap } from 'gsap';

	export default {
		name: 'MainNavigation',

		data() {
			return {
				showMenu: false,
				tl: null,
				navLinks: [
					{
						title: 'home',
						href: '/',
					},
					{
						title: 'about',
						href: '/about',
					},
					{
						title: 'skills',
						href: '/skills',
					},
					{
						title: 'contact',
						href: '/contact',
					},
				],
				socialLinks: [
					{
						href: 'https://www.xing.com/profile/Kerim_Hadri',
						title: 'Xing',
						icon: 'xing',
					},
					{
						href: 'https://de.linkedin.com/in/kerimhadri',
						title: 'LinkedIn',
						icon: 'linkedin',
					},
					{
						href: 'https://codepen.io/projekt8',
						title: 'CodePen',
						icon: 'codepen',
					},
					{
						href: 'https://twitter.com/projekt8_kerim',
						title: 'Twitter',
						icon: 'twitter',
					},
					{
						href: 'https://github.com/projekt8',
						title: 'Github',
						icon: 'github',
					},
				],
			};
		},

		watch: {
			$route: function() {
				this.showMenu = false;
			},
		},

		mounted() {
			this.initTimeline();
		},

		methods: {
			initTimeline() {
				this.tl = gsap.timeline({
					ease: 'expo.out',
					smoothChildTiming: false,
					paused: true,
				});

				// nav toggle
				this.tl.to(
					this.$refs.navToggle.querySelector(['[data-name=".open"']).childNodes,
					{
						duration: 0.3,
						y: '10px',
						scale: 0,
						opacity: 0,
						transformOrigin: 'center',
						stagger: {
							each: 0.05,
						},
					},
					0
				);
				this.tl.to(
					this.$refs.navToggle.querySelector(['[data-name=".close"']).childNodes[0],
					{
						duration: 0.25,
						y: '-5px',
						rotation: '45deg',
						transformOrigin: 'center',
					},
					0.1
				);
				this.tl.to(
					this.$refs.navToggle.querySelector(['[data-name=".close"']).childNodes[1],
					{
						duration: 0.25,
						y: '-5px',
						rotation: '-45deg',
						transformOrigin: 'center',
					},
					0.1
				);
				// nav links
				this.tl.from(
					this.$refs.navLinklist.childNodes,
					{
						duration: 0.5,
						opacity: 0,
						scale: 0,
						y: '100%',
						stagger: {
							each: 0.1,
						},
					},
					0.2
				);
				// metalinks
				this.tl.from(
					this.$refs.navMetaLinks,
					{
						duration: 0.6,
						opacity: 0,
					},
					0.6
				);
				// social links
				this.tl.from(
					this.$refs.socialLinklist.childNodes,
					{
						duration: 0.6,
						scale: 0,
						opacity: 0,
						stagger: {
							each: 0.1,
						},
					},
					0.2
				);
				// social title
				this.tl.from(
					this.$refs.socialTitle,
					{
						duration: 0.4,
						opacity: 0,
						scale: 0,
						y: -50,
					},
					0.2
				);
				// claim content
				this.tl.from(
					this.$refs.claim.childNodes,
					{
						duration: 0.4,
						opacity: 0,
						x: -100,
					},
					0.2
				);
				// nav links bg
				this.tl.from(this.$refs.mainNav, { duration: 0.4, top: '100%' }, 0);
				// claim bg
				this.tl.from(this.$refs.claim, { duration: 0.4, right: '100%' }, 0);
				// social bg
				this.tl.from(this.$refs.socialmedia, { duration: 0.4, bottom: '100%' }, 0);
				// cell4 bg
				this.tl.from(this.$refs.cell4, { duration: 0.4, left: '100%' }, 0);
				// cell5 bg
				this.tl.from(this.$refs.cell5, { duration: 0.4, top: '100%' }, 0);
			},

			openMenu(el, done) {
				this.$store.dispatch('disableScrolling', true);
				clearTimeout(this.showPageScrollbar);

				this.tl.timeScale(1);
				this.tl.eventCallback('onComplete', done);
				this.tl.play();
			},

			closeMenu(el, done) {
				this.tl.timeScale(2);
				this.tl.eventCallback('onReverseComplete', done);
				this.tl.reverse();
			},

			afterCloseMenu() {
				this.showPageScrollbar = setTimeout(() => {
					this.$store.dispatch('disableScrolling', false);
				}, 150);
			},
		},
	};
</script>

<style lang="scss" scoped>
	@keyframes navToggleHover {
		0% {
			transform: scaleX(1) translateX(0);
		}
		100% {
			transform: scaleX(0.7) translateX(-5px);
		}
	}

	.nav {
		display: flex;

		&__icon {
			height: 30px;
			pointer-events: none;
			transition: transform 300ms ease-out;
		}

		&__toggle {
			position: relative;
			z-index: z-index(#{&});
			width: 50px;
			height: 50px;
			margin: -5px -10px 0 0;
			padding: 0;
			background: none;
			border: none;

			&--close {
				.nav__icon {
					transform: translateY(-5px);
				}

				@media (any-hover: hover) {
					&:hover {
						.nav__icon {
							transform: translateY(-5px) scale(1.2);
						}
					}
				}
			}

			&:focus {
				outline: none;
			}

			@media (any-hover: hover) {
				&:hover:not(&--close) {
					.nav__icon {
						[data-name='.open'] path,
						[data-name='.close'] {
							animation: navToggleHover 220ms ease-out;
							animation-direction: alternate;
							animation-iteration-count: 2;
							animation-fill-mode: backwards;

							&:nth-child(2) {
								animation-delay: 0.1s;
							}
							&:nth-child(3) {
								animation-delay: 0.2s;
							}
						}
					}
				}
			}
		}

		&__menu {
			position: fixed;
			width: 100%;
			height: 100%;
			overflow: hidden;
			top: 0;
			left: 0;
			z-index: z-index(#{&});
			text-align: center;
			display: grid;
			grid-template-columns: 100%;
			grid-template-rows: 80% 20%;
			grid-template-areas: 'cell1' 'cell3';
			counter-reset: routeIndex;

			@include breakpoint($large) {
				grid-template-columns: 40% 60%;
				grid-template-rows: 60% 40%;
				grid-template-areas:
					'cell2 cell1'
					'cell3 cell1';
			}

			@include breakpoint($xlarge) {
				grid-template-columns: 20% 30% 50%;
				grid-template-rows: 60% 40%;
				grid-template-areas:
					'cell5 cell2 cell1'
					'cell4 cell3 cell1';
			}
		}

		&__linklist {
			display: grid;
			grid-template-columns: repeat(1, auto);
			gap: var(--spacing-sm) var(--spacing-xxl);
			align-self: center;
			margin-top: calc(var(--spacing-page));
			list-style: none;
			padding: 0;
			margin: 0;

			@include breakpoint($small-landscape) {
				grid-template-columns: repeat(2, auto);
			}
		}

		&__main-link {
			position: relative;
			display: flex;
			font-size: var(--text-xxxl);
			font-weight: bold;
			color: var(--color-white);
			transform-origin: left center;
			transition: transform 300ms ease-out;

			@include breakpoint($small-landscape) {
				font-size: var(--text-xxl);
			}

			&:hover {
				transform: scale(1.1);
			}

			&:before {
				display: block;
				counter-increment: routeIndex;
				content: '0' counter(routeIndex);
				width: 25px;
				height: 25px;
				line-height: 25px;
				font-size: var(--text-sm);
				font-family: var(--font-copy);
				font-weight: 600;
				color: var(--color-primary);
				margin: 0 var(--spacing-sm) 0 -25px;
			}

			&.router-link-exact-active {
				color: var(--color-primary);

				&:before {
					color: var(--color-white);
					opacity: 0.5;
				}
			}
		}

		&__meta {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			position: absolute;
			bottom: var(--spacing-page);
			right: var(--spacing-page);
			font-size: var(--text-sm);
			margin: 0 calc(-1 * var(--spacing-md)) calc(-1 * var(--spacing-sm)) 0;

			&-link {
				display: block;
				padding: var(--spacing-sm) var(--spacing-md);
				opacity: 0.6;
				transition: opacity 300ms ease-out, color 300ms ease-out;

				&:hover {
					opacity: 1;
				}

				&.router-link-exact-active {
					color: var(--color-primary);
					opacity: 1;
				}
			}
		}

		&__cell {
			$cell: &;
			position: relative;
			height: 100%;
			overflow: hidden;

			&-inner {
				position: absolute;
				height: 100%;
				width: 100%;
				overflow: hidden;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				background: var(--color-secondary-dark-20);
			}

			&--1 {
				grid-area: cell1;
			}

			&--2 {
				grid-area: cell2;
			}

			&--3 {
				grid-area: cell3;
			}

			&--4 {
				grid-area: cell4;
			}

			&--5 {
				grid-area: cell5;

				#{$cell}-inner {
					background: var(--color-secondary-dark-30);
				}
			}
		}

		&__claim {
			background: var(--color-primary);
			text-align: left;

			.title {
				font-size: var(--text-xl);
				word-break: break-word;
				align-self: center;
				margin: 0 var(--spacing-lg) 0 var(--spacing-lg);

				.highlight {
					background: var(--color-white);
				}
			}
		}

		&__socialmedia {
			background: var(--color-secondary-dark-40);

			@include breakpoint($small-landscape) {
				flex-flow: row nowrap;
				justify-content: flex-start;
				padding: 0 var(--spacing-page);
			}
		}

		&__social-title {
			font-size: var(--text-md);
			color: var(--color-primary);
			margin-bottom: 0;

			@include breakpoint($small-landscape) {
				margin-bottom: 0;
				margin-right: var(--spacing-md);
				font-size: var(--text-sm);
			}

			@include breakpoint($medium) {
				margin-bottom: var(--spacing-sm);
			}
		}

		&__social-linklist {
			flex-flow: row nowrap;
			list-style-type: none;
			margin: 0;
			padding: 0;

			li {
				display: inline-block;
			}
		}

		&__social-link {
			display: inline-flex;
			padding: var(--spacing-xs);
			margin: var(--spacing-xs);
			transition: transform 300ms ease-out;

			&:hover {
				.nav__social-icon {
					transform: scale(1.3);
				}
			}
		}

		&__social-icon {
			filter: invert(1);
			width: 20px;
			height: 20px;
			transition: all 300ms ease-out;

			@include breakpoint($small-landscape) {
				width: 17px;
				height: 17px;
			}
		}
	}
</style>
