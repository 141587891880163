<template>
	<div class="parallax-container">
		<slot />
		<div class="motion-request" v-if="device.showMotionRequest">
			<button class="motion-request__button" @click="requestMotionPermission">
				<img
					src="@/assets/svg/icon-smartphone-waves.svg"
					class="motion-request__icon"
					alt=""
				/>
				enable device orientaion
			</button>
		</div>
	</div>
</template>

<script>
	import Parallax from 'parallax-js';
	import debounce from 'lodash.debounce';
	import { mapState } from 'vuex';

	export default {
		name: 'MotionParallaxContainer',

		props: {
			layers: {
				// Array of layer objects: [{ name: 'character', depth: 0.5 }]
				type: Array,
				required: true,
			},
		},

		computed: {
			...mapState(['device']),
		},

		mounted() {
			if (this.device.showMotionRequest == false) {
				this.initParallaxEffect();
				this.createIntersectionObserver();
			}
		},

		methods: {
			requestMotionPermission() {
				this.$store.dispatch('showMotionRequest', false);

				window.DeviceOrientationEvent.requestPermission()
					.then((response) => {
						if (response === 'granted') {
							this.initParallaxEffect();
							this.createIntersectionObserver();
							this.$store.dispatch('setMotionPermission', true);
						} else {
							alert('DeviceOrientationEvent permission not granted.');
						}
					})
					.catch((e) => {
						alert(e);
					});
			},

			initParallaxEffect() {
				// get parallax layers and set layer depths
				this.layers.forEach((layer) => {
					this.$el
						.querySelectorAll('[data-name=".' + layer.name + '"]')
						.forEach((layerEl) => {
							layerEl.dataset.depth = layer.depth;
						});
				});

				// init parallax scene
				this.parallaxInstance = new Parallax(this.$el, {
					selector: '[data-depth]',
					limitX: 30,
					limitY: 30,
					invertX: false,
					invertY: true,
				});
			},

			createIntersectionObserver() {
				const observer = new IntersectionObserver(this.toggleParallax, {
					root: null,
					rootMargin: '0px',
					threshold: 0.7,
				});

				observer.observe(this.$el);
			},

			toggleParallax(entries, observer) {
				entries.forEach((entry) => {
					// Each entry describes an intersection change for one observed target element
					if (entry.isIntersecting) {
						this.parallaxInstance.enable();
					} else {
						this.parallaxInstance.disable();
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.parallax-container {
		position: relative;
	}

	.motion-request {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		&__button {
			position: relative;
			display: inline-flex;
			align-items: center;
			height: 50px;
			border-radius: 50px;
			font: inherit;
			font-weight: 700;
			white-space: nowrap;
			background-color: var(--color-primary);
			color: var(--color-white);
			border: none;
			outline: none;
			pointer-events: auto;
			box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
			animation: pulsate 1000ms ease-out infinite;
		}

		&__icon {
			margin-right: var(--spacing-xs);
		}
	}
</style>
