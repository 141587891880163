const HoverDirective = {
	bind(el, binding, vnode) {
		// add custom mouse hover events
		el.addEventListener('mouseenter', (e) => {
			HoverDirective.mouseEventHandler(vnode, {
				hover: true,
				target: e.currentTarget,
				type: binding.value || el.tagName,
			});
		});

		el.addEventListener('mouseleave', (e) => {
			HoverDirective.mouseEventHandler(vnode, { hover: false });
		});

		// add custom form focus/blur events
		if (el.tagName == 'INPUT' || el.tagName == 'TEXTAREA') {
			el.addEventListener('focus', (e) => {
				HoverDirective.mouseEventHandler(vnode, {
					focus: true,
					target: e.currentTarget,
					type: binding.value || el.tagName,
				});
			});

			el.addEventListener('blur', (e) => {
				HoverDirective.mouseEventHandler(vnode, { focus: false });
			});
		}
	},

	unbind(el, binding, vnode) {
		// cleanup event listeners
		el.removeEventListener('mouseenter', HoverDirective.mouseEventHandler);
		el.removeEventListener('mouseleave', HoverDirective.mouseEventHandler);

		if (el.tagName == 'INPUT' || el.tagName == 'TEXTAREA') {
			el.removeEventListener('focus', HoverDirective.mouseEventHandler);
			el.removeEventListener('blur', HoverDirective.mouseEventHandler);
		}

		HoverDirective.mouseEventHandler(vnode, { hover: false });
	},

	mouseEventHandler(vnode, payload) {
		// dispatch mouse cursor config to store
		vnode.context.$store.dispatch('mouseCursor', payload);
	},
};

export default HoverDirective;
