<template>
	<div class="route-info">
		<div class="route-info__number" ref="number">
			{{ $route.meta.index }}
		</div>
		<div class="route-info__line" ref="line"></div>
		<div class="route-info__name" ref="name">{{ $route.name }}</div>
	</div>
</template>

<script>
	import { gsap } from 'gsap';

	export default {
		name: 'RouteInfo',

		watch: {
			$route: function(newValue) {
				gsap.set(this.$refs.line, { width: 100 });
				gsap.to(this.$refs.line, {
					duration: 0.3,
					width: 20,
					repeat: 1,
					yoyo: true,
					ease: 'power1.inOut',
					overwrite: true,
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.route-info {
		position: fixed;
		bottom: var(--spacing-page);
		left: var(--spacing-page);
		display: none;
		align-items: center;
		font-size: var(--text-sm);
		opacity: 0.6;

		@include breakpoint($large) {
			display: flex;
		}

		@include breakpoint($large) {
			z-index: z-index(#{&});
		}

		&__line {
			width: 100px;
			height: 1px;
			background: var(--color-white);
			margin: 0 var(--spacing-xs);
		}
	}
</style>


