<template>
	<span class="logo">projekt <sup class="logo__number">8</sup></span>
</template>

<script>
	export default {
		name: 'SiteLogo',
	};
</script>

<style lang="scss" scoped>
	.logo {
		position: relative;
		z-index: z-index(#{&});
		display: inline-block;
		font-family: var(--font-heading);
		font-weight: 700;
		font-style: normal;
		font-size: 20px;
		line-height: 15px;
		color: var(--color-white);
		text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

		&__number {
			color: var(--color-primary);
		}
	}
</style>
