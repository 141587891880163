<template>
	<div class="bg-grid" ref="grid" :class="{ 'bg-grid--overlay': isOverlay }">
		<div v-for="item in 25" :class="'cell cell--' + item" :key="item"></div>
	</div>
</template>

<script>
	import { gsap } from 'gsap';

	export default {
		name: 'BackgroundGrid',

		props: {
			isOverlay: {
				type: Boolean,
				default: false,
			},
		},

		mounted() {
			if (!this.isOverlay) {
				this.animateRandomCell();
			}
		},

		methods: {
			getRandomChildren() {
				const elements = this.$refs.grid.childNodes;
				const shuffledElements = Array.from(elements).sort(function() {
					return 0.5 - Math.random();
				});

				return shuffledElements;
			},

			animateRandomCell() {
				const targets = this.getRandomChildren();
				const tl = gsap.timeline({
					repeat: -1,
					defaults: {
						duration: 1.7,
						repeat: 1,
						yoyo: true,
						ease: 'none',
					},
				});

				tl.to(targets, {
					opacity: 0.3,
					stagger: {
						each: gsap.utils.random(0.5, 1.8),
					},
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.bg-grid {
		position: fixed;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100vw;
		height: 100%;
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-template-rows: repeat(5, 1fr);
		gap: 1px;

		@include breakpoint(orientation portrait) {
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: repeat(8, 1fr);
		}

		@include breakpoint($small-landscape) {
			grid-template-columns: repeat(5, 1fr);
			grid-template-rows: repeat(4, 1fr);
		}

		.cell {
			background: var(--color-secondary);
		}

		&--overlay {
			position: fixed;
			z-index: z-index(#{&});
			gap: 0;

			.cell {
				opacity: 0;
				background: var(--color-secondary-dark-10);
				border: 0.5px solid var(--color-secondary);
			}
		}
	}
</style>
