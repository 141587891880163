<template>
	<component
		:is="type"
		:to="route"
		:href="href || route || scrollTo"
		class="button"
		:class="`button--${variant}`"
		v-hover="'cta'"
	>
		<span class="text" ref="buttonText">
			<slot />
		</span>
		<span v-if="icon" :class="`icon icon--${icon}`">
			<img
				:src="require(`@/assets/svg/icon-${icon}.svg`)"
				alt="icon"
				class="icon__svg"
				ref="buttonIcon"
				width="20"
				height="20"
			/>
		</span>
	</component>
</template>

<script>
	import { gsap } from 'gsap';
	import { ScrollToPlugin } from 'gsap/ScrollToPlugin.js';
	import { TextPlugin } from 'gsap/TextPlugin.js';
	gsap.registerPlugin(ScrollToPlugin, TextPlugin);

	export default {
		name: 'BaseButton',

		props: {
			icon: {
				type: String,
				default: null,
			},
			text: {
				type: String,
				default: null,
			},
			variant: {
				type: String,
				default: 'default',
			},
			href: {
				type: String,
				default: null,
			},
			scrollTo: {
				type: String,
				default: null,
			},
			route: {
				type: String,
				default: null,
			},
		},

		computed: {
			type() {
				if (this.href || this.scrollTo) {
					return 'a';
				} else if (this.route) {
					return 'router-link';
				} else {
					return 'button';
				}
			},
		},

		watch: {
			text(value) {
				gsap.to(this.$refs.buttonText, {
					duration: 0.3,
					text: { value: value },
					ease: 'power2.inOut',
				});
			},

			icon(value) {
				gsap.fromTo(
					this.$refs.buttonIcon,
					{ scale: 0 },
					{ duration: 0.4, scale: 1, ease: 'power2.inOut' }
				);
			},
		},

		mounted() {
			if (this.scrollTo) {
				this.$el.addEventListener('click', this.smoothScrollTo);
			}
		},

		destroyed() {
			if (this.scrollTo) {
				this.$el.removeEventListener('click', this.smoothScrollTo);
			}
		},

		methods: {
			smoothScrollTo(e) {
				e.preventDefault();

				gsap.to(window, {
					scrollTo: e.currentTarget.hash,
					duration: 1,
					ease: 'power2.inOut',
				});

				// target.scrollIntoView({ behavior: 'smooth', block: 'start' });
			},
		},
	};
</script>

<style lang="scss" scoped>
	.button {
		position: relative;
		display: inline-flex;
		align-items: center;
		height: var(--button-height);
		border-radius: var(--button-height);
		font: inherit;
		font-weight: 700;
		background-color: transparent;
		color: var(--color-white);
		border: none;
		outline: none;
		padding: var(--spacing-sm) var(--spacing-lg);
		margin: var(--spacing-lg) 0 0 0;
		transition: transform 300ms ease-out;

		.text {
			position: relative;
			white-space: nowrap;
			transition: transform 300ms ease-out;
		}

		.icon {
			display: inline-flex;
			transition: transform 300ms ease-out;
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: var(--button-height);
			height: var(--button-height);
			border-radius: var(--button-height);
			background-color: var(--color-primary);
			opacity: 0.5;
			transition: width 250ms ease-out, transform 300ms ease-out, opacity 300ms ease-out,
				background-color 500ms ease-out;
		}

		&:hover,
		&:focus {
			&:before {
				width: 100%;
				opacity: 0.8;
			}
		}

		&:active {
			transform: scale(0.9);
		}

		&--simple {
			&:hover,
			&:focus {
				animation: pulsate 1000ms ease-out infinite;
				// -webkit-backface-visibility: hidden;
			}

			&:before {
				opacity: 0.8;
				width: 100%;
			}
		}

		&--default {
			.text {
				transform: translateX(-8px);
			}

			.icon {
				margin-left: var(--spacing-md);
				transform: translateX(-15px);
				transition: transform 300ms ease-out;
			}

			&:hover,
			&:focus {
				.text {
					transform: translateX(0);
				}

				.icon {
					transform: translateX(0) scale(1.2);
				}
			}
		}

		&--reverse,
		&--scroll,
		&--contact {
			flex-direction: row-reverse;

			.icon {
				transform: translateX(-9px);
				margin-right: var(--spacing-md);
			}

			.text {
				transform: translateX(-20px);
			}

			&:hover,
			&:focus {
				.icon {
					transform: translateX(0) scale(1.2);
				}

				.text {
					transform: translateX(0);
				}
			}
		}

		&--scroll {
			&:hover,
			&:focus {
				.icon {
					transform: translateX(0);
				}
			}

			.icon__svg {
				animation: moveUpDown 1500ms linear infinite;
			}
		}

		&--contact {
			.icon__svg {
				margin-top: -3px;
			}
		}

		&--sending {
			.icon__svg {
				animation: moveLeftRight 1500ms linear infinite;
			}
		}

		&--error {
			&:before {
				background-color: var(--color-error);
			}
		}

		&--success {
			&:before {
				background-color: var(--color-success);
			}
		}
	}
</style>
