<template>
	<div class="cursor-container">
		<div class="cursor cursor--outer" ref="outerCursor"></div>
		<div
			class="cursor cursor--inner"
			ref="innerCursor"
			:class="{ 'cursor--stuck': isStuck }"
		></div>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import { gsap } from 'gsap';

	export default {
		name: 'MouseCursor',

		data() {
			return {
				clientX: 0,
				clientY: 0,
				isStuck: false,
				innerCursor: {
					width: 0,
					height: 0,
				},
				outerCursor: {
					width: 0,
					height: 0,
					speed: 0,
				},
			};
		},

		computed: {
			...mapState(['mouseCursor']),
		},

		watch: {
			mouseCursor(cursor) {
				cursor.hover ? this.cursorEnter() : this.cursorLeave();
				// cursor.focus ? this.cursorFocus() : this.cursorBlur();
			},
		},

		mounted() {
			this.innerCursor = {
				width: this.$refs.innerCursor.getBoundingClientRect().width,
				height: this.$refs.innerCursor.getBoundingClientRect().height,
			};

			this.outerCursor = {
				width: this.$refs.outerCursor.getBoundingClientRect().width,
				height: this.$refs.outerCursor.getBoundingClientRect().height,
			};

			document.addEventListener('mousemove', this.initCursor);
			document.addEventListener('mousemove', this.setCursorCoordinates);
		},

		destroyed() {
			document.removeEventListener('mousemove', this.initCursor);
			document.removeEventListener('mousemove', this.setCursorCoordinates);

			cancelAnimationFrame(this.updateCursor);
		},

		methods: {
			initCursor() {
				this.updateCursor();

				gsap.set(this.$refs.innerCursor, {
					x: this.clientX - this.innerCursor.width / 2,
					y: this.clientY - this.innerCursor.height / 2,
				});

				gsap.set(this.$refs.outerCursor, {
					x: this.clientX - this.outerCursor.width / 2,
					y: this.clientY - this.outerCursor.height / 2,
				});

				setTimeout(() => {
					this.outerCursor.speed = 0.3;
				}, 100);

				document.removeEventListener('mousemove', this.initCursor);
			},

			setCursorCoordinates(e) {
				this.clientX = e.clientX;
				this.clientY = e.clientY;
			},

			updateCursor() {
				gsap.set(this.$refs.innerCursor, {
					x: this.clientX - this.innerCursor.width / 2,
					y: this.clientY - this.innerCursor.height / 2,
				});

				if (!this.isStuck) {
					gsap.to(this.$refs.outerCursor, {
						duration: this.outerCursor.speed,
						x: this.clientX - this.outerCursor.width / 2,
						y: this.clientY - this.outerCursor.height / 2,
					});
				}

				requestAnimationFrame(this.updateCursor);
			},

			cursorEnter() {
				gsap.to(this.$refs.outerCursor, {
					duration: 0.4,
					scale: 0.5,
					opacity: 0.5,
					borderLeftWidth: this.outerCursor.width / 2,
					borderTopWidth: this.outerCursor.width / 2,
					borderRightWidth: this.outerCursor.width / 2,
					borderBottomWidth: this.outerCursor.width / 2,
					ease: 'expo.out',
					overwrite: true,
				});
			},

			cursorLeave() {
				this.isStuck = false;

				gsap.to(this.$refs.outerCursor, {
					duration: 0.3,
					scale: 1,
					opacity: 1,
					borderLeftWidth: 2,
					borderTopWidth: 2,
					borderRightWidth: 2,
					borderBottomWidth: 2,
					ease: 'expo.out',
					overwrite: true,
				});
			},

			cursorFocus() {},
			cursorBlur() {},
		},
	};
</script>

<style lang="scss">
	html,
	a,
	input,
	textarea,
	button {
		cursor: none;
	}

	.cursor {
		position: fixed;
		left: 0;
		top: 0;
		pointer-events: none;
		border-radius: 50%;
		transform: translate(-100%, -100%);

		&--outer {
			width: 50px;
			height: 50px;
			border: 2px solid var(--color-primary);
			z-index: 99998;
			transform-origin: 50% 50%;
		}

		&--inner {
			width: 5px;
			height: 5px;
			z-index: 99999;
			background: var(--color-white);
		}
	}
</style>
